@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.Team {
    color: white;
}

.Team__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.Person__container {
    display: flex;
    justify-content: center;
    width: 500px;
}

.Person {
    width: 456px;
    height: 666px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 64px;
}

.Person__Name {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
}

.Person__Designation {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 275;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
}

.Person__image {
    width: 100%;
    height: 456px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.Person__social {
    width: 280px;
    height: 72px;
    background: rgba(16, 16, 16, 0.6);
    border-radius: 12px;
    margin-bottom: 19px;
    position: absolute;
    top: 456px;
    transition: top .5s ease;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Person_socialIcon {
    color: #FFDE59;
    font-size: 40px;
    cursor: pointer;
}

.Person__image:hover .Person__social {
    top: 365px;
}

@media screen and (max-width: 1000px) {
    .Person {
        width: 80vw;
        height: auto;
    }

    .Person__container {
        width: 80vw;
    }

    .Person__social {
        position: static;
        width: 90%;
    }

    .Person__image {
        height: 85vw;
    }

    .Person__Name {
        font-size: 30px;
    }
}
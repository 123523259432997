.Footer__contact {
    display: flex;
    flex-direction: column;
    color: var(--white);
    margin: 32px 0;
}

.Footer__contactHeading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.Footer__contactSubHeading {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #BFBEBE;
    cursor: pointer;
    overflow-wrap: break-word;
}

.Footer__icon {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #101010;
}

.Footer__bottom {
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--yellow);

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #101010;

    padding: 0 12%;
    text-align: center;
}

.Footer__socials {
    display: none;
}

@media screen and (max-width: 1000px){

    .Footer__socials {
        display: block;
    }

    .Footer__contactDiv {
        flex-direction: column;
        align-items: flex-start;
    }

    .Footer__contactHeading {
        font-size: 30px;
    }
    
    .Footer__contactSubHeading {
        font-size: 28px;
    }

    .Footer__icon {
        font-size: 24px;
        margin-right: 16px;
    }

    .Footer__bottom {
        height: auto;
        padding: 5% 12%;
    }

    .Footer__bottom {
        font-size: 16px;
    }
}